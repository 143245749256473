.container {
  height: 50px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  height: 100%;
  color: var(--primary-text-color);
  font-size: 40px;
}

.button:hover {
  color: var(--tertiary-color);
}
