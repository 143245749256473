.container {
  display: flex;
  padding: 5px;
  position: relative;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  background-color: var(--tertiary-color);
}

.warning {
  background-color: red;
}

.dragBox {
  background: rgba(255, 255, 255, 0.496);
  border: 1px solid rgb(0, 0, 0);
  border-radius: 50%;
  position: absolute;
  width: 13px;
  height: 13px;
  float: left;
  z-index: 2;
}

.dragBox:hover {
  background: rgba(255, 0, 0, 0.383);
}

.dragBox:active {
  background: rgba(34, 0, 255, 0.383);
}

.disabled {
  border-radius: 0%;
}
.selected {
  background: rgba(34, 0, 255, 0.383);
}

.dragBoxSelected {
  background: rgba(57, 16, 114, 0.383);
}

.svgContainer {
  pointer-events: none;
  position: absolute;
}

.spline {
  fill: none;
  stroke: black;
  stroke-width: 3px;
}

.overhang {
  stroke: yellow;
}
